.header {
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: var(--maxGlobalWidth);
    z-index: 100;
}

.logo {
    font-weight: normal;
    font-size: 26px;
    line-height: 1.19;
    letter-spacing: 0.03em;
    color: #B71C1C;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 258px;
}

.logo span {
    color: black;
}

.navBtn {
    text-decoration: none;
    color: black;
}

.navBtn:hover {
    color: #B71C1C;
    background-color: transparent !important;
}