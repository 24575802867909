@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --maxGlobalWidth: 1920px;
    --maincolor: #F5E0D5FF;
    --basecolor: #6d6875;
    --accentcolor1: #f5b8a9;
    --accentcolor2: #e1c9d7;
    --accentcolor3: #b5838d;
    --whitecolor: #ffffff;
}

html {
    font-size: 16px;
}

body {
    width: 100%;
    max-width: var(--maxGlobalWidth);
    font-family: 'Roboto Condensed', sans-serif;
    margin: 0 auto;
}


@media screen and (max-width: 1024px) {
    html {
        font-size: 12px;
    }

}